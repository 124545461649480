<template>
  <div class="public-box">
    <div class="list-box">
      <div class="item">
        <span>线上合同消息设置</span>
        <MyButton @click="lineContractMesage" :accessId="33856">设置</MyButton>
      </div>
      <div class="item">
        <span>合同到期预警设置</span>
        <MyButton @click="expireContractMessage" :accessId="18174">设置</MyButton>
      </div>
      <div class="item">
        <span>合同到期消息通知设置</span>
        <MyButton @click="contracthasexpireMessage" :accessId="10891">设置</MyButton>
      </div>
      <div class="item">
        <span>线下合同签订地址设置</span>
        <MyButton @click="address" :accessId="79212">设置</MyButton>
      </div>
      <div class="item">
        <span>合同审批步骤设置</span>
        <MyButton @click="getagreesetting" :accessId="79212">设置</MyButton>
      </div>
    </div>
    <!-- 线上合同消息 -->
    <Confirm ref="linecontract" type="slot" @confirm="confirmset('contractToSignSetting')">
      <template slot="content">
        <linecontractmessage :data="contractToSignSetting.values"></linecontractmessage>
      </template>
    </Confirm>
    <!-- 合同到期预警 -->
    <Confirm ref="contractexpireVue" :disabled="!(
      contractWarningSetting.values &&
      contractWarningSetting.values.surplus_day
    )
      " type="slot" @confirm="confirmset('contractWarningSetting')">
      <template slot="content">
        <contractexpireVue :data="contractWarningSetting.values"></contractexpireVue>
      </template>
    </Confirm>
    <!-- 合同到期消息设置 -->
    <Confirm ref="contracthasexpireVue" :disabled="!contractStopSetting.values.surplus_day ||
      contractStopSetting.values.surplus_day < 0
      " type="slot" @confirm="confirmset('contractStopSetting')">
      <template slot="content">
        <contracthasexpireVue :data="contractStopSetting.values"></contracthasexpireVue>
      </template>
    </Confirm>
    <!-- 线下合同签订地址设置 -->
    <el-dialog :close-on-click-modal="false" title="线下合同签订地址设置" :visible.sync="dialogVisible" width="520px"
      ref="addressSetting">
      <el-form ref="form" :model="addressSetting" label-width="140px">
        <el-form-item label="线下合同签订地点">
          <el-row v-for="(item, index) in addressSetting.values.list" :key="index" style="marginbottom: 20px">
            <el-col :span="12">
              <el-form-item :prop="`values.list[${index}].content`" :rules="{
                required: true,
                message: '请输入地址',
                trigger: 'blur',
              }">
                <el-input placeholder="请输入地址" v-model="item.content" class="public-input"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" :offset="2">
              <MyButton type="error" @click="delRental(index)">删除</MyButton>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <MyButton @click="addAddres">添加</MyButton>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton type="primary" @click="confirmAddres">确定</MyButton>
      </span>
    </el-dialog>
    <!-- 合同审批步骤设置 -->
    <el-dialog title="合同审批步骤设置" :visible.sync="agreestepsvisible" :close-on-click-modal="false">
      <el-radio-group v-model="ContractIsAgreeSetting.values.steps">
        <div style="margin: 10px">
          <el-radio :label="12">不审核</el-radio>
        </div>
        <div style="margin: 10px">
          <el-radio :label="0">审核(第一步，第二步，第三步，第四步，第五步，第六步，第七步，第八步，第九步，第十步，第十一步，第十二步)</el-radio>
        </div>
        <div style="margin: 10px">
          <el-radio :label="1">审核(第二步，第三步，第四步，第五步，第六步，第七步，第八步，第九步，第十步，第十一步，第十二步)</el-radio>
        </div>
        <div style="margin: 10px">
          <el-radio :label="2">审核(第三步，第四步，第五步，第六步，第七步，第八步，第九步，第十步，第十一步，第十二步)</el-radio>
        </div>
        <div style="margin: 10px">
          <el-radio :label="3">审核(第四步，第五步，第六步，第七步，第八步，第九步，第十步，第十一步，第十二步)</el-radio>
        </div>
        <div style="margin: 10px">
          <el-radio :label="4">审核(第五步，第六步，第七步，第八步，第九步，第十步，第十一步，第十二步)</el-radio>
        </div>
        <div style="margin: 10px">
          <el-radio :label="5">审核(第六步，第七步，第八步，第九步，第十步，第十一步，第十二步)</el-radio>
        </div>
        <div style="margin: 10px">
          <el-radio :label="6">审核(第七步，第八步，第九步，第十步，第十一步，第十二步)</el-radio>
        </div>
        <div style="margin: 10px">
          <el-radio :label="7">审核(第八步，第九步，第十步，第十一步，第十二步)</el-radio>
        </div>
        <div style="margin: 10px">
          <el-radio :label="8">审核(第九步，第十步，第十一步，第十二步)</el-radio>
        </div>
        <div style="margin: 10px">
          <el-radio :label="9">审核(第十步，第十一步，第十二步)</el-radio>
        </div>
        <div style="margin: 10px">
          <el-radio :label="10">审核(第十一步，第十二步)</el-radio>
        </div>
        <div style="margin: 10px">
          <el-radio :label="11">审核(第十二步)</el-radio>
        </div>
      </el-radio-group>
      <template #footer>
        <myButton @click="agreestepsvisible = false">取消</myButton>
        <myButton type="primary" left @click="setSteps">确认</myButton>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import Confirm from "../../../unit/components/confirm.vue";
import linecontractmessage from "./setingchild/linecontractmessage.vue";
import contractexpireVue from "./setingchild/contractexpire.vue";
import contracthasexpireVue from "./setingchild/contracthasexpire.vue";
export default {
  data () {
    return {
      settingvisible: false,
      contractToSignSetting: {
        key: "contractToSignSetting",
        describe: "线上合同消息设置",
        values: {
          content: "",
        },
      },
      contractWarningSetting: {
        key: "contractWarningSetting",
        describe: "合同到期预警设置",
        values: {
          surplus_day: 40, //提前多少天提醒
          surplus_status: "", //
          content: "",
        },
      },
      contractStopSetting: {
        key: "contractStopSetting",
        describe: "合同到期消息通知设置",
        values: {
          surplus_day: 7, //提前多少天提醒
          content: "",
        },
      },
      addressSetting: {
        key: "contractAddress",
        describe: "线下合同签订地址设置",
        values: {
          list: [],
        },
      },
      ContractIsAgreeSetting: {
        key: "ContractIsAgreeSetting",
        describe: "线下合同签订地址设置",
        values: {
          steps: 0,
        },
      },
      dialogVisible: false,
      agreestepsvisible: false,
    };
  },
  methods: {
    addAddres () {
      this.addressSetting.values.list.push({
        content: "",
      });
    },
    async getDetail (type) {
      let res = await this.$request.HttpGet(`/setting/edit?key=${type}`);
      if (res.data && res.data.values) {
        this[type].values = res.data.values;
      }
    },
    async lineContractMesage () {
      await this.getDetail("contractToSignSetting");
      console.log("fs");
      this.$refs.linecontract.title = "线上合同消息设置";
      await this.$refs.linecontract.fade();
    },
    async expireContractMessage () {
      await this.getDetail("contractWarningSetting");
      console.log("fs");
      this.$refs.contractexpireVue.title = "合同到期预警设置";
      await this.$refs.contractexpireVue.fade();
    },
    async contracthasexpireMessage () {
      await this.getDetail("contractStopSetting");
      console.log("fs");
      this.$refs.contracthasexpireVue.title = "合同到期消息通知设置";
      await this.$refs.contracthasexpireVue.fade();
    },
    async address () {
      this.$request
        .HttpGet("/setting/edit", {
          key: "contractAddress",
        })
        .then((res) => {
          if (res.data) {
            if (!res.data.values) {
              res.data.values = {
                list: [],
              };
            }
            this.addressSetting = res.data;
          }
          this.dialogVisible = true;
        });
    },
    confirmset (type) {
      if (this[type].values && this[type].values.surplus_day) {
        this[type].values.surplus_day *= 1;
      }
      this.$request.HttpPost("/setting/add", this[type]).then((res) => {
        this.$common.notifySuccess(res.msg);
      });
    },
    confirmAddres () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$request
            .HttpPost("/setting/add", this.addressSetting)
            .then((res) => {
              this.$common.notifySuccess(res.msg);
              this.dialogVisible = false;
            });
        }
      });
    },
    // 删除地址
    delRental (index) {
      this.addressSetting.values.list.splice(index, 1);
    },
    // 步骤设置
    getagreesetting () {
      this.$request
        .HttpGet("/setting/edit", {
          key: "ContractIsAgreeSetting",
        })
        .then((res) => {
          if (res.data) {
            if (!res.data.values) {
              res.data.values = { steps: 0 };
            }
            this.ContractIsAgreeSetting = res.data;
          }
          this.agreestepsvisible = true;
        });
    },
    setSteps () {
      this.$request
        .HttpPost("/setting/add", this.ContractIsAgreeSetting)
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.agreestepsvisible = false;
        });
    },
  },
  components: {
    Confirm,
    linecontractmessage,
    contractexpireVue,
    contracthasexpireVue,
  },
};
</script>
<style lang="scss">
.list-box {
  width: 750px;
  // height: 208px;
  background: #ffffff;
  border: 1px solid #dedede;

  .item {
    width: 100%;
    height: 52px;
    padding: 0 60px 0 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
