<template>
  <div class="editarea">
    <div class="edit-content">
      <div class="inserttitle" v-if="txtList.length>0">
        <span>消息类容编辑：</span>
        <MyButton left v-for="(item,index) in txtList" :key="index" @click="inserted(`“${item.name}”`)">{{item.name}}</MyButton>
      </div>
      <!-- <input style="width:100px;border: 1px solid red;" id="inputed" type="text" @input="inserted" /> -->
      <el-input
        v-model="data[value]"
        type="textarea"
        placeholder=""
        ref="inputed"
        :id="elId"
        :rows="10"
        class="insertarea"
      ></el-input>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    elId: {
      type: String,
      required: true,
    },
    txtList: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      required: true,
    },
    value: {
      type: String, //字段名字
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    inserted(value) {
      let inputed = document.getElementById(this.elId);
      let startPops = inputed.selectionStart;
      let endProps = inputed.selectionEnd;
      if (!value) return false;
      let result = "";
      if (this.data[this.value]) {
        result =
          this.data[this.value].slice(0, startPops) +
          value +
          this.data[this.value].slice(endProps, this.data[this.value].length);
      } else {
        result = value 
      }
      inputed.value = result;
      inputed.focus();
      inputed.selectionStart = startPops + value.length;
      inputed.selectionEnd = startPops + value.length;
      this.data[this.value] = result;
      this.$emit("getResult", result);
    },
  },
};
</script>
<style lang='scss'>
.editarea{
  width: 100%;
  // width: 300px;
  // background: red;
}
.insertarea {
  width: 500px;
  margin-top: 20px;
}
.inserttitle{
  display: flex;
  align-items: center;
}
.edit-content{
  // width: 100%;
  // background: red;
}
</style>