<template>
<div>
  <el-form ref="form" label-width="220px">
    <el-form-item label="合同剩余天数≤">
      <el-input placeholder="输入数字" v-model.trim="data.surplus_day" style="width:113px;margin:0 10px" type="number"></el-input>
    </el-form-item>
    <el-form-item label="合同即将到期续签消息自动发送">
      <el-switch v-model="data.surplus_status" active-text="开启" inactive-text="关闭"></el-switch>
    </el-form-item>
  </el-form>
  <insertTextareaVue :txtList="txtList" elId="contractexpire" value="content" :data="data"></insertTextareaVue>
</div>
</template>
<script>
import insertTextareaVue from "../../../../components/insertTextarea.vue";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          surplus_day: 40,
          surplus_status: false,
          content: ''
        }
      }
    }
  },
  components: {
    insertTextareaVue,
  },
  data() {
    return {
      txtList: [{
          name: "商户姓名",
          value: 0
        },
        {
          name: "合同编号",
          value: 1
        },
        {
          name: "商铺号",
          value: 2
        },
        {
          name: "天数",
          value: 3
        }
      ]
    };
  },
};
</script>
<style lang='scss'>
.xydy {
    width: 10px;
    height: 10px;
}
.setitem {
    height: 50px;
}
</style>
