<template>
<div>
  <insertTextareaVue :txtList="txtList" elId="linecontract" value="content" :data="data"></insertTextareaVue>
</div>
</template>
<script>
import insertTextareaVue from "../../../../components/insertTextarea.vue";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          content: ''
        }
      }
    }
  },
  components: {
    insertTextareaVue,
  },
  data() {
    return {
      txtList: [{
          name: "商户姓名",
          value: 0
        },
        {
          name: "合同编号",
          value: 1
        }
      ]
    };
  },
};
</script>
<style lang='scss'>
</style>
