<template>
<div>
  <el-form ref="form" label-width="110px">
    <el-form-item label="合同剩余天数≤">
      <el-input placeholder="输入数字" style="width: 270px;"  v-model="data.surplus_day" type="number">
        <template slot="append">天时发送消息通知</template>
      </el-input>
     
    </el-form-item>
    <el-form-item label="">
       <span style="color: red;" v-if="data.surplus_day < 0">天数不能小于0</span>
    </el-form-item>
  </el-form>
  <insertTextareaVue :txtList="txtList" elId="hascontractexpire" value="content" :data="data"></insertTextareaVue>
</div>
</template>
<script>
import insertTextareaVue from "../../../../components/insertTextarea.vue";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          surplus_day: '',
          content: ''
        }
      }
    }
  },
  components: {
    insertTextareaVue,
  },
  data() {
    return {
      txtList: [{
          name: "商户姓名",
          value: 0
        },
        {
          name: "合同编号",
          value: 1
        },
        {
          name: "商铺号",
          value: 2
        },
        {
          name: "天数",
          value: 3
        }
      ]
    };
  },
  methods:{
  }
};
</script>
<style lang='scss'>
.xydy {
    width: 10px;
    height: 10px;
}
.setitem {
    height: 50px;
}
</style>
